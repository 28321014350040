import React from "react"
import { Link, graphql } from "gatsby"
import Img, { FluidObject } from "gatsby-image"
import Layout from "../components/layout"
import { Player, LoadingSpinner, BigPlayButton, PosterImage } from "video-react"
import ReactMarkdown from "react-markdown"
import "github-markdown-css"

interface Props {
  data: {
    strapiProject: {
      id: string
      title: string
      content: string
      excerpt: string
      poster_image: {
        childImageSharp: {
          fluid: FluidObject
        }
        publicURL: string
      }
      project_video: {
        publicURL: string
      }
      slug: string
      projectcategory: {
        name: string
      }
      published_at: string
    }
  }
  pageContext: PageContextData
}

interface PageContextData {
  id: string
  prevProject: {
    title: string
    path: string
  }
  nextProject: {
    title: string
    path: string
  }
}

export const query = graphql`
  query ProjectQuery($id: String!) {
    strapiProject(strapiId: { eq: $id }) {
      id
      title
      poster_image {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
        publicURL
      }
      project_video {
        publicURL
      }
      slug
      content
      excerpt
      projectcategory {
        name
      }
      published_at
    }
  }
`

const Project: React.FC<Props> = ({ data, pageContext }) => {
  const project = data.strapiProject
  return (
    <Layout>
      <div className="mb-4">
        <Link
          className="font-mont font-bold transition ease-in hover:text-blackish"
          to="/"
        >
          Home
        </Link>{" "}
        &#x27B2;{" "}
        <Link
          className="font-mont font-bold transition ease-in hover:text-blackish"
          to="/project"
        >
          Project
        </Link>{" "}
        &#x27B2;{" "}
        <span className="font-mont font-bold ">
          {project.projectcategory.name}
        </span>
      </div>

      <div className="bg-white text-blackish py-8 px-4 sm:p-8 rounded-md mb-10 flex flex-col">
        <h1 className="text-3xl font-monda md:text-5xl font-bold mb-2">
          {project.title}
        </h1>
        <p className="font-bold font-monda">
          {project.excerpt.slice(0, 200) + "..."}
        </p>
        <div className="flex justify-between items-center">
          <p className="font-mont text-sm">
            By <strong>John Phung</strong>
          </p>
          <p className="font-mont text-sm">{project.published_at}</p>
        </div>
        <Player
          src={project.project_video.publicURL}
          poster={project.poster_image.publicURL}
        >
          <BigPlayButton position="center" />
          <LoadingSpinner />
        </Player>
        <button className="bg-transparent border-white border text-white p-2 w-full text-sm mt-2 sm:mt-2 focus:outline-none">
          <Link className="font-mont " to={`/project/${project.slug}`}>
            View Detail &#xbb;
          </Link>
        </button>

        <div className="text-md py-12 font-mont sm:text-lg float-right w-full font-medium leading list-disc">
          <div className="markdown-body ">
            <ReactMarkdown source={project.content} />
          </div>
        </div>

        <hr />

        {pageContext.prevProject.title && pageContext.nextProject.title && (
          <div className="flex my-12 bg-black grid grid-cols-2 font-mont rounded py-2 px-4">
            {pageContext.prevProject.title !== "" && (
              <div className="flex flex-col col-start-1 text-white">
                <span className="text-sm font-monda mb-2">
                  &#x219e; Previous Article
                </span>
                <Link
                  to={pageContext.prevProject.path}
                  className="my-0 w-fit font-mont"
                >
                  {pageContext.prevProject.title}
                </Link>
              </div>
            )}

            {pageContext.nextProject.title !== "" && (
              <div className="flex flex-col col-start-2 text-white">
                <span className="text-sm font-monda text-right mb-2">
                  Next Article &#x21a0;
                </span>
                <Link
                  to={pageContext.nextProject.path}
                  className="my-0 text-right w-fit font-mont"
                >
                  {pageContext.nextProject.title}
                </Link>
              </div>
            )}
          </div>
        )}
      </div>
    </Layout>
  )
}

export default Project
